import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { PrismicRichText } from '@prismicio/react';
import ButtonGetStarted from '../Buttons/ButtonGetStarted'
import ButtonWatchDemo from '../Buttons/ButtonWatchDemo'
import FeaturesList from "../Elements/FeaturesList"
import RichOrText from "../Elements/RichOrText";
import SectionGradient from "../Elements/SectionGradient";

const HeroSection = ({
  slice: {
    primary: {
      heading,
      homeHeroDescription,
      homeHeroSubtitle,
      homeHeroTitle,
      hero_image,
      hero_mobile_image,
    },
    items,
  },
}) => (
  <div className="background1 md:pt-64 pt-40 relative">
    <div className="max-w-4xl lg:max-w-5xl xl:max-w-7xl mx-auto px-4 z-10 relative">
      <div className="relative text-center Herotitle">
        <span className="stripes1 absolute" />
        <RichOrText
          content={heading.richText}
          components={{
            heading1: ({ children }) => (
              <h1 className="xl:text-8xl lg:text-7xl sm:text-6xl text-5xl text-blue font-medium tracking-tighter mb-8">
                {children}
              </h1>
            ),
          }}
        />
        <span className="stripes2 absolute" />
      </div>
      <PrismicRichText
        field={homeHeroDescription?.richText}
        components={{
          paragraph: ({ children }) => (
            <p className="text-blue text-xl mx-auto text-center mb-3">
              {children}
            </p>
          ),
        }}
      />
      <div className="btns flex justify-center items-center text-center md:flex-row flex-col md:gap-4 mt-8">
        <ButtonGetStarted />
        <ButtonWatchDemo />
      </div>
    </div>
    <div>
      <div className="md:block hidden lg:-mt-48 -mt-12" >
        <GatsbyImage image={getImage(hero_image)} alt={heading.text} />
      </div>
      <div className="md:hidden block mt-8">
        <StaticImage src="../../assets/images/Home/hero-bg_mob2.png" /> 
      </div>
    </div>
    <div className="max-w-7xl mx-auto px-4 -mt-16 z-30 relative">
      <FeaturesList items={items}/>
    </div>
    <div>
      <SectionGradient color="white" position="bottom" />
    </div>
  </div>
);

export default HeroSection;

export const query = graphql`
  fragment HomeHeroSlice on PrismicHomepageDataBodyHero {
    id
    slice_type
    primary {
      heading {
        richText
        text
      }
      homeHeroDescription:description {
        richText
      }
      hero_image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    items {
      feature_text
      feature_count {
        text
        html
      }
    }
  }
`
